import 'jquery-ui/sortable';
import 'jquery-ui-css/all.css';
import 'jquery-ui-touch-punch';

import printConfiguration from './print-configuration.js';

export function organiseFrontPageParticipants() {
  $('.program-print-frontPage #program-print-participants').each(function () {
    const nbParticipants = $(this).children('div').length;
    const nbMaxLines = $(this).data('nb-max-lines');
    const nbColumns = Math.ceil(nbParticipants / nbMaxLines);

    const DOMOffset = $(this).offset().top;
    const nextDOMOffset = $(this).next().offset().top;
    const maxHeight = nextDOMOffset - DOMOffset;

    reorderParticipants($(this));
    resizeParticipants($(this), nbColumns, maxHeight);
  });
}

export function organiseVoucherParticipants() {
  $('.program-print-voucher #program-print-participants').each(function () {
    const nbParticipants = $(this).children('div').length;
    reorderParticipants($(this));
    resizeParticipants($(this), Math.min(nbParticipants, 3));
  });
}

function reorderParticipants(DOM) {
  // Order participants
  const ordered = printConfiguration.get('participants');
  if (ordered) {
    const actual = DOM.children()
      .toArray()
      .map((p) => $(p).data('participant-id'));
    const toOrder = ordered.filter((p) => actual.includes(p));

    toOrder.forEach((id, index) => {
      const participant = DOM.children(`[data-participant-id=${id}]`);
      if (participant.length == 1) {
        DOM.children(`:nth-child(${index + 1})`).before(participant);
      }
    });
  }

  // Event
  DOM.sortable({
    revert: true,
    start: function () {
      DOM.sortable('option', 'cancel', '#program-print-participants');
      DOM.addClass('program-print-participant-sorting');
    },
    stop: function () {
      DOM.sortable('option', 'cancel', null);
      DOM.removeClass('program-print-participant-sorting');
    },
    update: function () {
      const participantsOrder = $('#program-print-participants')
        .children()
        .toArray()
        .map((p) => $(p).data('participant-id'));
      printConfiguration.saveParticipantsOrder(participantsOrder);
    },
  });
}

function resizeParticipants(DOM, nbColumns, maxHeight = 100000) {
  switch (nbColumns) {
    case 1:
      break;
    case 2:
      DOM.children('div').removeClass('col-xs-12').addClass('col-xs-6');
      break;
    default:
      DOM.children('div').removeClass('col-xs-12').addClass('col-xs-4');
  }

  let fontSize = 24;
  let minMaxHeights = getMinMaxParticipantsHeight(DOM);
  let height = DOM.outerHeight();

  while (
    (height > maxHeight || minMaxHeights.min < minMaxHeights.max) &&
    fontSize >= 8
  ) {
    fontSize--;
    DOM.css('font-size', fontSize);
    minMaxHeights = getMinMaxParticipantsHeight(DOM);
    height = DOM.outerHeight();
  }

  // In case this was not enought, the participants will be transfered to another page
  if (height > maxHeight || minMaxHeights.min < minMaxHeights.max) {
    DOM.removeAttr('style');
    DOM.parent()
      .find('.program-print-participants-title')
      .toggleClass('hidden');
  }
}

function getMinMaxParticipantsHeight(DOM) {
  const heights = DOM.find('.program-print-participant')
    .map(function () {
      return $(this).height();
    })
    .get();

  return {
    min: Math.min.apply(null, heights),
    max: Math.max.apply(null, heights),
  };
}
