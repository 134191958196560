import { initializeFormFields, displaySubformAsArray } from 'table/form';

// Subform Indexes insuring the Sections Unicity inside each Subform
let subformSectionIndexes = {};

function incrementSubformIndex(subformSectionId) {
  if (subformSectionIndexes[subformSectionId] === undefined) {
    subformSectionIndexes[subformSectionId] = 0;
  } else {
    subformSectionIndexes[subformSectionId]++;
  }

  return subformSectionIndexes[subformSectionId];
}

export function initializeSubformAsSection() {
  displaySubformAsSection();
  defineEvents();
}

function displaySubformAsSection(container = '') {
  $(container + ' .tp-table-subform-section').each(function () {
    if (!$(this).data('subform-loaded')) {
      // Initialisation
      const subform = $(this);
      const subformSectionId = subform.attr('id');

      // Display the Subform
      subform.children('.form-group').each(function () {
        const position = incrementSubformIndex(subformSectionId);

        const section = formatSection($(this), position);
        subform.append(section);
      });

      // Add Button
      const addButtonText = $(`#${subformSectionId}_label`).data('add-button');
      if (addButtonText) {
        subform.append(createAddLine(subformSectionId, addButtonText));
      }

      // Loaded
      subform.data('subform-loaded', true);
    }
  });
}

function formatSection(section, position) {
  // Format label
  const labelName = $('<span>')
    .addClass('col-sm-12  col-sx-4 tp-table-subform-section-label')
    .text('N°' + (position + 1));
  const deleteButton = $('<button>')
    .attr('type', 'button')
    .addClass('col-sm-12 col-sx-8 tp-table-subform-section-delete')
    .addClass(' btn btn-danger glyphicon glyphicon-trash');
  const label = $('<label>')
    .addClass('col-sm-1')
    .append(labelName)
    .append(deleteButton);

  section.prepend(label);

  // Format widget
  section.children('div').removeClass('col-sm-10').addClass('col-sm-11');

  // Wrap
  return $('<div>').addClass('well').append(section);
}

function createAddLine(subformSectionId, buttonText) {
  const button = $('<div>')
    .data('subform-id', subformSectionId)
    .addClass('tp-table-subform-section-add-button')
    .addClass('btn btn-success btn-sm')
    .text(buttonText);
  const align = $('<div>').addClass('col-sm-10 col-sm-offset-2').html(button);
  return $('<div>').addClass('row tp-table-subform-section-add').html(align);
}

function defineEvents() {
  // Add a Section
  $('.table-form-row').on(
    'click',
    '.tp-table-subform-section-add-button',
    function () {
      let subformSectionId = $(this).data('subform-id');
      addNewSection(subformSectionId);
    }
  );

  //Remove a Section
  $('.table-form-row').on(
    'click',
    '.tp-table-subform-section-delete',
    function () {
      let container = $(this).closest('.well');
      $(container).remove();
    }
  );
}

export function addNewSection(
  subformSectionId,
  { prototype = null, displaySection = true } = {}
) {
  // Initialisation
  const container = $('#' + subformSectionId);
  const position = incrementSubformIndex(subformSectionId);
  const sectionId = `${subformSectionId}_${position}`;

  prototype = prototype ?? container.data('prototype');

  // Get potential Subform Arrays
  const subformArrays = $(prototype)
    .find('.tp-table-subform-array')
    .toArray()
    .map((n) => n.id.replace(`${subformSectionId}___name___`, ''));

  // Update Prototype
  subformArrays.forEach((id) => {
    prototype = prototype
      .replace(new RegExp(`${id}___name__`, 'g'), `µµ${id}µµ`) // Subform in subform protection
      .replace(new RegExp(`\\[${id}\\]\\[__name__\\]`, 'g'), `££${id}££`); // Subform in subform protection
  });
  prototype = prototype.replace(new RegExp('__name__', 'g'), position);
  subformArrays.forEach((id) => {
    prototype = prototype
      .replace(new RegExp(`µµ${id}µµ`, 'g'), `${id}___name__`) // Subform in subform protection
      .replace(new RegExp(`££${id}££`, 'g'), `[${id}][__name__]`); // Subform in subform protection
  });

  // Format & Display Section
  const section = formatSection($(prototype), position);
  section.attr('id', `${sectionId}_container`).addClass('hidden');

  if (container.find('.tp-table-subform-section-add').length == 0) {
    container.append(section);
  } else {
    container.find('.tp-table-subform-section-add').before(section);
  }
  if (subformArrays.length > 0) {
    displaySubformAsArray('#' + sectionId);
  }
  initializeFormFields('#' + sectionId);

  if (displaySection) {
    section.removeClass('hidden');
  }
  return sectionId;
}
