import { initializeFormFields } from './js/form-fields.js';
import { initializeSubformAsArray } from './js/subform-as-array.js';
import { initializeSubformAsSection } from './js/subform-as-section.js';
import { defineMapEvents } from './js/map.js';

import './css/flags.scss';
import './css/form.scss';
import './css/subforms.scss';
import './css/translation.scss';

export function form() {
  $(function () {
    initializeSubformAsSection();
    initializeSubformAsArray();
    initializeFormFields();
    defineMapEvents();

    // Display Form
    $('.table-form-row').removeClass('invisible');
  });
}
