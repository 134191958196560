import 'eonasdan-bootstrap-datetimepicker/src/js/bootstrap-datetimepicker.js';
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css';
import 'moment/locale/fr';

import { displayMapInputValue } from './map.js';
import { activateMapHelper, activateMarkdownHelper } from 'table/helper';

export function initializeFormFields(container = '') {
  // Read Only Fields
  displayReadonlyFields(container);

  // Date & Time Pickers
  datepicker(container);
  timepicker(container);

  // Map
  displayMapInputValue(container);

  // Labels - Translation
  displayFlags(container);

  // Entity Links
  activateEntityLinks(container);

  // Helpers
  activateMapHelper(container);
  activateMarkdownHelper(container);
}

function displayReadonlyFields(container = '') {
  const fields = ['select[readonly]', 'input[type="number"][readonly]'];
  const fieldsSelector = fields.map((s) => container + ' ' + s).join(',');

  $(fieldsSelector).each(function () {
    if (!$(this).data('readonly-done')) {
      $(this).addClass('hidden').attr('readonly', false);

      let id = $(this).attr('id');
      let value = $(this).val();
      let label;

      if (value) {
        if ($(this).is('select')) {
          if (typeof value === 'object') {
            label = [];
            let select = $(this);
            $.each(value, function (key, val) {
              label.push(select.children('option[value=' + val + ']').text());
            });
            label = label.join(', ');
          } else {
            label = $(this)
              .children('option[value=' + value + ']')
              .text();
          }
        } else {
          label = value;
        }
      }

      let readOnlyField = $(
        '<input id="' +
          id +
          '_label" readonly="readonly" class="form-control" type="text">'
      );
      readOnlyField.val(label);
      $(this).after(readOnlyField);

      $(this).data('readonly-done', true);
    }
  });

  $(container + ' input[type="checkbox"][readonly]').each(function () {
    if (!$(this).data('readonly-done')) {
      $(this).attr('readonly', false);
      let readOnlyField = $(this).clone();
      $(this).addClass('hidden');

      readOnlyField
        .prop('disabled', true)
        .attr('id', readOnlyField.attr('id') + '_readOnly');
      $(this).after(readOnlyField);

      $(this).data('readonly-done', true);
    }
  });
}

// Date & Time Pickers
function datepicker(container = '') {
  $(container + ' input[type=datepicker]')
    .parent()
    .datetimepicker({
      locale: $('html').attr('lang'),
      format: 'DD/MM/YYYY',
      widgetPositioning: { horizontal: 'left' },
    });
}

function timepicker(container = '') {
  $(container + ' input[type=timepicker]')
    .parent()
    .datetimepicker({
      locale: $('html').attr('lang'),
      format: 'HH:mm',
      widgetPositioning: { horizontal: 'left' },
    });
}

// Translation
function displayFlags(container = '') {
  $(container + ' .tp-table-translatable').each(function () {
    if ($(this).children('i').length === 0) {
      let flagClass = $(this).data('flag');
      let flag = $('<i>').addClass(flagClass);
      $(this).append(flag);
    }
  });
}

// Entity Links
function activateEntityLinks(container = '') {
  $(container + ' .tp-table-entity-link i').click(function () {
    window.open($(this).data('link'));
  });
}
