import router from 'tools/router';
import dialog from 'tools/dialog';
import { addNewSection } from 'table/form';

import { loadGrids } from '../images/images.js';
import './day.scss';

export function dayActivities() {
  $(function () {
    formatActivitySection(`#${getDayFormId()} .tp-table-subform-section`);
    defineEvents();
  });
}

function getDayFormId() {
  return $('form[name^=day]').attr('name');
}

function formatActivitySection(container) {
  $(`${container} .form-group`).each(function () {
    // Rename label
    const activityType = $(this).find('[id$=_type]').val();
    const labelText = $('#AddToDay_' + activityType).data('name');
    $(this).find('.tp-table-subform-section-label').text(labelText);

    // Resize label
    $(this).children('label').removeClass('col-sm-1').addClass('col-sm-2');
    $(this).children('div').removeClass('col-sm-11').addClass('col-sm-10');

    // Display Optional Section
    displayOptionalRemark($(this).find('[id$=_optional]'));
  });
}

function defineEvents() {
  initializeAddButtons();
  initializeOptionalActivities();
}

function initializeOptionalActivities() {
  $(`#${getDayFormId()} .tp-table-subform-section`).on(
    'change',
    'input[id$=_optional]',
    function () {
      displayOptionalRemark($(this));
    }
  );
}

function displayOptionalRemark(input) {
  const sectionId = input.attr('id') + 'Section';
  const remarkId = input.attr('id') + 'Remark';

  if (input.is(':checked')) {
    $('#' + sectionId).removeClass('hidden');
    $('#' + remarkId)
      .closest('.form-group')
      .removeClass('hidden');
  } else {
    $('#' + sectionId).addClass('hidden');
    $('#' + remarkId)
      .closest('.form-group')
      .addClass('hidden');
  }
}

function getMaxOrder() {
  let maxOrder = 0;

  $('input[id$="_activityOrder"]').each(function () {
    const order = parseInt($(this).val());
    maxOrder = order > maxOrder ? order : maxOrder;
  });

  return Math.floor(maxOrder + 1);
}

function addNewActivity(
  programId,
  actionLabel,
  activityType,
  prefillService = null
) {
  dialog.loading(actionLabel);

  const routingParams = {
    program: programId,
    type: activityType,
  };
  if (prefillService) {
    routingParams.service = prefillService;
  }

  $.post(
    //URL
    router.generate(
      'tp_planner_program_confirmAddActivity_AJAX',
      routingParams
    ),
    // Success
    function (data) {
      const subformSectionId = $('.tp-table-subform-section').attr('id');
      const prototype = data
        .replace(
          new RegExp('name="add_activities___name__', 'g'),
          `name="${getDayFormId()}[activities][__name__]`
        )
        .replace(new RegExp('add_activities', 'g'), subformSectionId);

      const sectionId = addNewSection(subformSectionId, {
        prototype,
        displaySection: false,
      });

      const sectionContainerId = `#${sectionId}_container`;
      formatActivitySection(sectionContainerId);
      loadGrids(sectionContainerId);

      $(sectionContainerId).find('[id$=_type]').val(activityType);
      $(sectionContainerId).find('[id$=_activityOrder]').val(getMaxOrder());

      $(sectionContainerId).removeClass('hidden');
      dialog.close();
    }
  );
}

function displayActivitySelectionForm(
  programId,
  actionLabel,
  activityType,
  country,
  city
) {
  dialog.loading(actionLabel);

  const parameters = { program: programId, type: activityType };
  if (typeof country !== 'undefined') parameters.country = country;
  if (typeof city !== 'undefined') parameters.city = city;

  $.post(
    //URL
    router.generate('tp_planner_program_selectActivity_AJAX', parameters),

    // Success
    function (form) {
      dialog.open(actionLabel, form);

      $('#select_activity_country, #select_activity_city').change(function () {
        const countryVal = $('#select_activity_country').val();
        if (countryVal == '') {
          displayActivitySelectionForm(programId, actionLabel, activityType);
        } else {
          const cityVal = $('#select_activity_city').val();
          if (cityVal == '' || countryVal != country) {
            displayActivitySelectionForm(
              programId,
              actionLabel,
              activityType,
              countryVal,
              0
            );
          } else {
            displayActivitySelectionForm(
              programId,
              actionLabel,
              activityType,
              countryVal,
              cityVal
            );
          }
        }
      });

      if (!$('#select_activity_service').val()) {
        $('#AddActivityConfirmation').addClass('disabled');
      }

      $('#select_activity_service').change(function () {
        if ($('#select_activity_service').val()) {
          $('#AddActivityConfirmation').removeClass('disabled');
        } else {
          $('#AddActivityConfirmation').addClass('disabled');
        }
      });

      $('#AddEmptyActivityConfirmation').click(function () {
        addNewActivity(programId, actionLabel, activityType);
      });
      $('#AddActivityConfirmation').click(function () {
        const prefillService = $('#select_activity_service').val();

        if (prefillService) {
          addNewActivity(programId, actionLabel, activityType, prefillService);
        }
      });
      $('#AddActivityCancel').click(function () {
        dialog.close();
      });
    }
  );
}

function initializeAddButtons() {
  $('#AddActivityToDayButtons a').click(function () {
    const programId = $(this).parent().data('program-id');
    const actionLabel = $(this).text();
    const activityType = $(this).data('type');

    displayActivitySelectionForm(programId, actionLabel, activityType);
  });
}
