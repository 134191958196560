import 'jquery-ui/sortable';
import 'jquery-ui-css/all.css';
import 'jquery-ui-touch-punch';

import router from 'tools/router';
import dialog from 'tools/dialog';
import { initializeFormFields } from 'table/form';

import './css/program-header.scss';
import './css/template-popup.scss';
import './css/warning.scss';

// Set as a constant instead of just a function because of weird bug when building with webpack in mode Production.
// There is a bug in the console saying the function is not defined...
const programElementClick = function () {
  followProgramButton($(this));
};

export function programHeader() {
  $(function () {
    initializeProgramHeader();
    initializeTemplateManagement();
  });
}

function initializeProgramHeader() {
  const programElements = [
    '#program-header-program',
    '#program-header-days li',
    '#program-header-cotation-add',
    '#program-header-cotations li',
    '#program-header-booking-add',
    '#program-header-bookings li',
  ];
  $(programElements.join(',')).on('click', programElementClick);

  $('#program-header-day-add').on('click', function () {
    const newDayNumber = $('#program-header-days li').length + 1;
    followProgramButton($(this), ['__form_dayNumber=' + newDayNumber]);
  });

  $('#program-header-days').sortable({
    revert: true,
    start: function (_, ui) {
      ui.item.off('click', programElementClick);
      $('#program-header-days').sortable(
        'option',
        'cancel',
        '#program-header-days'
      );
    },
    stop: function (_, ui) {
      ui.item.on('click', programElementClick);
      $('#program-header-days').sortable('option', 'cancel', null);
    },
    update: function (_, ui) {
      $('#program-header-days li').each(function (index) {
        $(this).text(index + 1);
      });

      const routingParams = {
        day: ui.item.data('record-id'),
        dayNumber: ui.item.text(),
      };
      ui.item.addClass('small-loading');

      $.post(
        //URL
        router.generate('tp_planner_day_editDayNumber_AJAX', routingParams),
        // Success
        function () {
          followProgramButton(ui.item);
        }
      );
      // }
    },
  });
}

function followProgramButton(button, urlParameters = []) {
  if (button.data('action') == 'add') {
    const baseUrl = router.generate('tp_table_add', {
      table: button.data('table'),
    });
    urlParameters.push('__form_program=' + button.data('program'));

    window.location.href = baseUrl + '?' + urlParameters.join('&');
  } else {
    window.location.href = router.generate(
      'tp_table_' + button.data('action'),
      {
        table: button.data('table'),
        id: button.data('record-id'),
      }
    );
  }
}

function initializeTemplateManagement() {
  const templateToCopy = $('#program-header-copy-template');
  if (templateToCopy.length > 0) {
    displayTemplateCopyForm(templateToCopy);
  }

  const programToExtend = $('#program-header-import-template');
  if (programToExtend.length > 0) {
    displayTemplateImport(programToExtend);
  }
}

function displayTemplateCopyForm(template) {
  const templateId = template.data('template');
  const dialogTitle = template.text();

  template.click(function () {
    dialog.loading(dialogTitle);

    $.post(
      //URL
      router.generate('tp_planner_template_copyForm_AJAX'),

      // Success
      function (form) {
        dialog.open(dialogTitle, form, 600);

        initializeFormFields(dialog.DIALOG_ID);

        const dialogHeight = $(dialog.DIALOG_ID).css('height');
        $('#copy_template_departureDate')
          .parent()
          .on('dp.show', function () {
            $(dialog.DIALOG_ID).css('height', '350px');
          });
        $('#copy_template_departureDate')
          .parent()
          .on('dp.hide', function () {
            $(dialog.DIALOG_ID).css('height', dialogHeight);
          });

        $('#copy_template_departureDate')
          .parent()
          .on('dp.change', function () {
            if ($('#copy_template_departureDate').val()) {
              $('#CopyTemplateConfirmation').removeClass('disabled');
            } else {
              $('#CopyTemplateConfirmation').addClass('disabled');
            }
          });
        $('#CopyTemplateConfirmation').click(function () {
          if (!$(this).hasClass('diabled')) {
            copyTemplate(templateId, dialogTitle);
          }
        });
        $('#TemplateCancel').click(function () {
          dialog.close();
        });
      }
    );
  });
}

function copyTemplate(templateId, dialogTitle) {
  const departureDate = $('#copy_template_departureDate')
    .parent()
    .data('DateTimePicker')
    .date();
  const departureDateAsArray = {
    day: departureDate.format('D'),
    month: departureDate.format('M'),
    year: departureDate.format('YYYY'),
  };
  const mainContactId = $('#copy_template_mainContact').val();

  dialog.loading(dialogTitle);

  const postParams = {
    departureDateAsArray: departureDateAsArray,
    mainContactId: mainContactId,
  };

  $.post(
    //URL
    router.generate('tp_planner_template_copy_AJAX', {
      template: templateId,
    }),
    // Data
    postParams,
    // Success
    function (form) {
      dialog.open(dialogTitle, form, 600, true);
    }
  );
}

function displayTemplateImport(program) {
  const programId = program.data('program-id');
  const dialogTitle = program.text();

  program.click(function () {
    dialog.loading(dialogTitle);

    $.post(
      //URL
      router.generate('tp_planner_template_importForm_AJAX'),

      // Success
      function (form) {
        dialog.open(dialogTitle, form, 600);

        initializeFormFields(dialog.DIALOG_ID);

        $('#ImportTemplateConfirmation').click(function () {
          importTemplate(programId, dialogTitle);
        });
        $('#TemplateCancel').click(function () {
          dialog.close();
        });
      }
    );
  });
}

function importTemplate(programId, dialogTitle) {
  const templateId = $('#import_template_template').val();
  const filterDays = $('#import_template_filterDays').val();
  const importCotations = $('#import_template_importCotations').prop('checked');

  dialog.loading(dialogTitle);

  $.post(
    //URL
    router.generate('tp_planner_template_import_AJAX', {
      template: templateId,
    }),
    // Data
    { programId, filterDays, importCotations },
    // Success
    function (form) {
      dialog.open(dialogTitle, form, 600, true);
    }
  );
}
